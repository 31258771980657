<template>
  <div id="app">
    <!--    <datav/>-->
    <!--    <login/>-->
    <router-view></router-view>
  </div>
</template>
<script>
// import login from './views/LoginView.vue'

export default {
  name: 'app',
  components: {
    // datav
    // login
  },
  data() {
    return {}
  }
}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Lantinghei SC;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

body {
  //width: 100%;
  //height: 100%;
  //box-sizing: border-box;
  //background-image: url("./assets/bg.png");
  //background-size: 100% 100%;
}
</style>
