// import axios from "axios";
//
// // 创建一个单例（实例）
// const instance = axios.create({
//     // baseURL: "xxxxx"    xxxx为服务器地址
//     baseURL: "http://120.27.11.139:88",
//     // 设置超时时间，所有请求都会等待 4 秒
//     timeout: 4000,
// });
//
// // 拦截器 - 请求拦截器
// axios.interceptors.request.use(config => {
//
//
//     // 部分接口需要携带token
//     let token = 'mvm60syal33xwumx5lw9'
//     if (token) {
//         config.headers = 'Authorization' + token
//     }
//
//     /*
//         // 部分接口需要携带token
//         let token = localStorage.getItem('a-xxxx-token')
//         if (token) {
//             config.headers = {
//                 'a-xxx-token': token
//             }
//         }
//     */
//     return config
// }, error => {
//     // 请求失败抛出
//     return Promise.reject(error)
// })
//
//
// // 拦截器 - 响应拦截器
// axios.interceptors.response.use(res => {
//     // 响应成功抛出
//     return res
// }, error => {
//     // 响应失败抛出
//     return Promise.reject(error)
// })
//
//
// // 导出
// export default instance;


import axios from 'axios'
import router from "@/router";

const service = axios.create({
    baseURL: "https://zhanggui.wxsongzi.com", // url = base url + request url
    // baseURL: "http://120.27.11.139:88",
    timeout: 5000, // request timeout
})

service.interceptors.request.use(
    config => {
        // let token = 'mvm60syal33xwumx5lw9'
        let token = localStorage.getItem('token')
        console.log("requers", token)
        if (token) {
            config.headers['Authorization'] = token
        }
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    },
)
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code !== 200) {
            console.log('接口信息报错', res.message)
            // return Promise.reject(new Error(res.message || 'Error'))
            return res.message || 'Error'
        } else {
            return res
        }
    },
    error => {
        console.log('接口信息报错' + error)
        return error
    },
)

export default service
