<!--登录-->
<template>
  <div class="background">
    <div class="login">
      <div class="login-title">松籽数据中台登录</div>
      <div class="login-form">
        <div class="form-content">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px" class="demo-ruleForm">
            <el-form-item label="账号" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input v-model="ruleForm.password"></el-input>
            </el-form-item>


            <el-form-item>
              <el-button style="width: 100%;" type="primary" @click="submitForm('ruleForm')">立即登录</el-button>
            </el-form-item>
          </el-form>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
import {geAdminUserLoginApi, geGetStoreInfoListApi} from "@/request/api"
import router from "@/router";

export default {
  data() {
    return {
      ruleForm: {
        name: 'data_admin_account',
        password: 123456
      },
      rules: {
        name: [
          {required: true, message: '请输入账号', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
      }
    };
  },
  created() {
    // 获取店铺
    this.geGetStoreInfoList()
  },
  methods: {
    // 获取店铺
    geGetStoreInfoList() {
      geGetStoreInfoListApi().then(res => {
        if (res.code === 200) {

        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          geAdminUserLoginApi({
            account: this.ruleForm.name,
            password: this.ruleForm.password
          }).then(res => {
            if (res.code === 200) {
              this.$message({
                message: '登录成功',
                type: 'success'
              });
              let token = res.data.token
              localStorage.setItem('token', token)

              router.push(`/home`)

            } else {
              this.$message.error('登录失败');
            }
          })

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style scoped lang="scss">
.background {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat; //将图片样式不重复
  background-size: 100% 100%; //设置图片大小
  position: fixed; /* 充满全屏 */
  background-image: url("../assets/login.jpg");

  .login {
    box-sizing: border-box;
    width: 397px;
    height: 325px;
    border-radius: 10px;
    margin: 318px 1039px;
    background-color: white;

    .login-title {
      width: 100%;
      height: 50px;
      font-size: 20px;
      text-align: center;
      line-height: 50px;
      border: 1px solid #eee;
    }

    .login-form {
      width: 100%;
      height: 100%;

      .form-content {
        width: 340px;
        height: 100%;
        margin-top: 35px;
      }
    }
  }
}

</style>
