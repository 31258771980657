import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index.js'
import store from './store'
import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts;
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'

Vue.config.productionTip = false
Vue.use(dataV)
import axios from 'axios'

Vue.prototype.$axios = axios;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.use(ElementUI);
new Vue({
    router,
    // store,
    render: h => h(App)
}).$mount('#app')



