import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'


const routes = [{
    path: '/',
    redirect: '/login'
},
    {
        path: '/login',
        name: 'login',
        // component: LoginView
        component: () => import('../views/LoginView.vue')
    }
    ,

    {
        path: '/home',
        name: 'home',
        // component: HomeView,
        component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
]


Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'hash',
    // base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    let token = localStorage.getItem('token')
    if (!token && to.path !== '/login') {
        next('/login')
    } else if (token && to.path === '/login') {
        next('/home')
    } else {
        next()
    }
});
// router.beforeEach((to, from, next) => {
// let token = localStorage.getItem('token')

// if (!token) {
//
//     // 当 token 不存在时，重定向到登录页
//     next({path: '/login'});
// } else {
//     if (token && to.path == "/login") {
//         next("/home")
//     } else {
//         // 当 token 存在时，允许用户继续访问目标路由
//         next();
//     }
//
// }
// });

export default router


