// 引入request.js文件
import request from './http'

// export按需导出

// 获取用户消费数据
export const getConsumptionInfoApi = () => request.post('/DataManage/ConsumptionInfo/GetConsumptionInfo')

// 获取用户复购信息
export const getUserRepeatConsumptionInfoApi = () => request.post('/DataManage/ConsumptionInfo/GetUserRepeatConsumptionInfo')

// 获取实时数据
export const getRealTimeDataInfoApi = () => request.post('/DataManage/RealTime/GetRealTimeDataInfo')

//获取店铺统计信息
export const getStoreSortInfoListApi = () => request.post('/DataManage/Store/GetStoreSortInfoList')

//获取客流信息
export const getUserPassengerFlowInfoApi = () => request.post('/DataManage/UserInfo/GetUserPassengerFlowInfo')

//获取用户统计信息
export const getUserStatisticsInfoApi = () => request.post('/DataManage/UserInfo/GetUserStatisticsInfo')

//获取城市店铺分布
export const getStorePlaceInfoApi = () => request.post('/DataManage/Store/GetStorePlaceInfo')

//获取当前年的统计
export const getStatisticsByYearApi = () => request.post('/DataManage/Store/GetStatisticsByYear')

//获取当前月的数据统计
// export const getStatisticsByMonthApi = () => request.post('/DataManage/Store/GetStatisticsByMonth')

//登录
export const geAdminUserLoginApi = (data) => request.post('/StoreManage/SystemUser/AdminUserLogin', data)

//设置查询参数
export const geSetSearchParamApi = (data) => request.post('/DataManage/Config/SetSearchParam', data)

//店铺获取
export const geGetStoreInfoListApi = () => request.post('/DataManage/Store/GetStoreInfoList')


//折线图日期选择
export const getStatisticsByIntervalTimeApi = (data) => request.post('/DataManage/Store/GetStatisticsByIntervalTime', data)


//查询实时数据
export const getRealTimeDataInfoBySearchParamApi = () => request.post('/DataManage/RealTime/GetRealTimeDataInfoBySearchParam')
